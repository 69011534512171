import { H1, P } from "components/design-system"
import HubspotForm from "components/hubspot-form"
import Section, { Column } from "components/new-section"
import { locale } from "config/locale"
import * as React from "react"
import { FormattedMessage } from "react-intl"
import Layout from "templates/layout"

export default class Contact extends React.Component {
  render() {
    return (
      <Layout alwaysOnHeader={true} className="N10-bg" title="Contact | Stackchat">
        <Section
          partition="full-width"
          classes="space-inner-top-10 space-inner-bottom-10"
        >
          <Column className="col-12 text-center">
            <H1>
              <FormattedMessage id="contact.heading" />
            </H1>

            <P>
              <FormattedMessage id="contact.description-line-1" />
            </P>
            <P>
              <FormattedMessage id="contact.description-line-2" />
            </P>
          </Column>

          <Column className="col-12 col-md-6 offset-md-3 contact-form text-left space-outer-top-6">
            <HubspotForm
              light={false}
              portalId="3371700"
              formId={locale === "zh" ? (
                "65ab0f1a-f0af-4e5a-bc9b-0db6781f6858"
              ) :
                "05686a2c-7f73-4adf-ab01-750dcbcf06be"
              }
            />
          </Column>
        </Section>
      </Layout>
    )
  }
}